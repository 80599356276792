<template>
  <div class="affiliates-pages-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
        <a-col :span="18">
          <h3 class="mb-0" style="font-size: 20px;">Affiliates codes</h3>
        </a-col>
        <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
            <a-button type="primary" class="ml-4" size="large" @click="openCreateModal(null)">
                <a-icon type="plus" /> Create new
            </a-button>
        </a-col>
    </a-row>
    <!-- eslint-disable -->
    <a-card>
       <a-table
            :columns="columns"
            :data-source="codes"
            :rowKey="(record, index) => index"
            :loading="tableLoading"
            :pagination="pagination"
            @change="tableChange"
            :customRow="customRow"
          >
            <template slot="actions" slot-scope="text, record">
              <div class="row-operations">
                <!-- <span>
                  <a class="font-size-18 text-primary edit-icon" href="javascript:;"><a-icon type="edit" /></a>
                </span> -->
                <span>
                  <a-popconfirm
                    title="Sure to delete this code with linked users?"
                    @confirm="() => deleteCode(record.id)"
                  >
                    <a class="font-size-18 text-danger delete-icon"  href="javascript:;"><a-icon type="delete" /></a>
                  </a-popconfirm>
                </span>
              </div>
          </template>
      </a-table>
    </a-card>

     <code-modal
      ref="code-modal"
      :code="code"
      @update="tableUpdate"
      @cancel="codeModalHandleCancel"
    />
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from '@/services/axios'

const templateCode = {
  id: null,
  code: '',
  limit: 1000,
}

export default {
  components: {
    'code-modal': () => import('./CodeModal.vue'),
  },
  data: () => ({
    code: { ...templateCode },
    codes: [],
    pagination: {
      pageSize: 7,
      current: 1,
      total: 0,
    },
    tableLoading: false,
    columns: [
      {
        title: 'code',
        dataIndex: 'code',
        width: '30%',
      },
      {
        title: 'limit',
        dataIndex: 'limit',
        width: '30%',
      },
      {
        title: 'created at',
        dataIndex: 'created_at',
        width: '25%',
      },
      {
        title: 'Actions',
        width: '15%',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  async created() {
   await this.tableUpdate()
  },
  methods: {
    updateCode(code) {
      this.code.code = code;
    },
    customRow(code) {
      const _this = this;

      return {
        on: {
          click: (e) => {
            if (e.target.closest('.edit-icon')) {
              _this.openCreateModal(code);
              return false;
            }
            if (e.target.closest('.delete-icon')) {
              return false;
            }
            _this.$router.push(`/affiliates/${code.id}`);
          },
        },
      }
    },
    openCreateModal(obj) {
      if (obj) {
        this.code = { ...obj }
      }
      this.$nextTick(() => {
        this.$refs['code-modal'].showModal()
      })
    },
    async tableUpdate() {
      try {
        this.tableLoading = true
        await this.fetchCodes()
      } catch (e) {
        console.error(e)
      } finally {
        this.tableLoading = false
      }
    },
    async fetchCodes() {
      const { current, pageSize } = this.pagination
      const { data } = await apiClient.get(`admin/affiliates?page=${current}&perPage=${pageSize}`)

      data.data.map((element) => {
        element.created_at = this.$moment(element.created_at).format('DD.MM.YYYY HH:mm:ss');
        return element;
      });

      this.codes = data.data
      this.pagination.total = data.meta.total
    },
    async deleteCode(id) {
      try {
        this.tableLoading = true
        await apiClient.delete(`admin/affiliates/${id}`)
        this.$notification.success({
          message: 'Success',
          description: 'Code deleted successfully',
          duration: 2,
        })
        this.tableUpdate()
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.tableLoading = false
      }
    },
    tableChange(e) {
      this.pagination = e
      this.tableUpdate()
    },
    codeModalHandleCancel() {
      this.$nextTick(() => {
        this.code = { ...templateCode }
      });
    }
  }
}
</script>

<style lang="scss">
  .affiliates-pages-content {
    .ant-table-row {
      cursor: pointer;
    }
  }
</style>
